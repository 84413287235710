<template>
  <b-card :title="titleForm">
    <validation-observer ref="form">
      <b-form @submit.prevent>
        <b-row>
          <b-col cols="12" lg="12">
            <validation-provider
              #default="{ errors }"
              name="observacao"
              rules="required"
            >
              <b-form-group label="Profissional" label-for="v-profissional">
                <v-select
                  v-model="form.registro"
                  label="nome"
                  :filterable="false"
                  :options="comboProfissionals"
                  :selected="form.registro"
                  @search="onSearch"
                  :disabled="
                    (!verificarPermissao('ROLE_ATEND_ATUALIZAR') &&
                      !!form.id) ||
                    (!verificarPermissao('ROLE_ATEND_INSERIR') && !form.id)
                  "
                  ><template slot="no-options">
                    Pesquisa profissional por nome, cpf ou matrícula
                  </template>
                  <template slot="selected-option" slot-scope="option">
                    <div class="selected d-center">
                      {{ option.nome }}
                    </div>
                  </template>
                </v-select>
                <small data-cy="v-profissional" class="text-danger">{{
                  errors[0]
                }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>

          <!-- Data Inicio -->
          <b-col cols="12" lg="6">
            <validation-provider
              #default="{ errors }"
              name="Data Inicio"
              rules="required"
            >
              <b-form-group label="Data Inicio" label-for="v-dataInicio">
                <flat-pickr
                  v-model="form.dataInicio"
                  name="fieldDate"
                  :config="{
                    altInput: true,
                    altFormat: 'd/m/Y',
                    enableTime: false,
                    dateFormat: 'Y-m-d\\TH:i:ss\\Z'
                  }"
                  :disabled="
                    (!verificarPermissao('ROLE_ATEND_ATUALIZAR') &&
                      !!form.id) ||
                    (!verificarPermissao('ROLE_ATEND_INSERIR') && !form.id)
                  "
                />
                <small data-cy="v-profissional" class="text-danger">{{
                  errors[0]
                }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>

          <!-- Data FIM -->
          <b-col cols="12" lg="6">
            <b-form-group label="Data Termino" label-for="v-dataTermino">
              <flat-pickr
                v-model="form.dataTermino"
                name="fieldDate"
                :config="{
                  altInput: true,
                  altFormat: 'd/m/Y',
                  enableTime: false,
                  dateFormat: 'Y-m-d\\TH:i:ss\\Z'
                }"
                :disabled="
                  (!verificarPermissao('ROLE_ATEND_ATUALIZAR') && !!form.id) ||
                  (!verificarPermissao('ROLE_ATEND_INSERIR') && !form.id)
                "
              />
            </b-form-group>
          </b-col>
          <b-col cols="12" md="6" lg="6">
            <validation-provider
              #default="{ errors }"
              name="Especialidades"
              rules="required"
            >
              <b-form-group label="Especialidades" label-for="v-especialidades">
                <v-select
                  v-model="form.especialidades"
                  label="nome"
                  :options="comboEspecialidades"
                  :state="errors.length > 0 ? false : null"
                  data-cy="tipo"
                  multiple
                  :disabled="
                    (!verificarPermissao('ROLE_ATEND_ATUALIZAR') &&
                      !!form.id) ||
                    (!verificarPermissao('ROLE_ATEND_INSERIR') && !form.id)
                  "
                />
                <small data-cy="v-especialidades" class="text-danger">{{
                  errors[0]
                }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>

          <b-col cols="12" md="6" lg="6">
            <b-form-group label="Conselho" label-for="v-conselho">
              <v-select
                v-model="form.conselho"
                label="descricao"
                :options="comboConselhos"
                data-cy="tipo"
                :disabled="
                  (!verificarPermissao('ROLE_ATEND_ATUALIZAR') && !!form.id) ||
                  (!verificarPermissao('ROLE_ATEND_INSERIR') && !form.id)
                "
              />
            </b-form-group>
          </b-col>

          <b-col v-if="form.id" cols="12">
            <b-form-group>
              <b-form-checkbox
                v-model="form.ativo"
                :checked="form.ativo"
                name="check-button"
                switch
                inline
                :disabled="
                  (!verificarPermissao('ROLE_ATEND_ATUALIZAR') && !!form.id) ||
                  (!verificarPermissao('ROLE_ATEND_INSERIR') && !form.id)
                "
              >
                {{ form.ativo ? 'Sim' : 'Não' }}
              </b-form-checkbox>
            </b-form-group>
          </b-col>

          <!-- submit and reset -->
          <ButtonsFormVue
            :permission-insert="verificarPermissao('ROLE_ATEND_INSERIR')"
            :permission-edit="verificarPermissao('ROLE_ATEND_ATUALIZAR')"
            :disable-form="false"
            @save="save()"
            @cancel="cancel()"
          />

          <b-col
            v-if="form.id && verificarPermissao('ROLE_USER_INSERIR')"
            cols="12"
            sm="6"
          >
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              v-b-modal.modal-perfil
              variant="success"
              class="mt-1"
              :disabled="!verificarPermissao('ROLE_ATEND_INSERIR')"
            >
              Cadastrar Usuário
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>

    <b-modal
      ref="my-modal"
      hide-footer
      id="modal-perfil"
      title="Selecionar Perfil"
    >
      <b-form-group label="Perfil">
        <v-select
          v-model="perfilSelecionado"
          :options="comboPerfilProfissional"
          label="nome"
          placeholder="Selecione um perfil"
        />
      </b-form-group>
      <b-button class="mt-1" variant="primary" block @click="handleSaveProfile">
        {{ isLoading ? 'Aguarde...' : 'Salvar' }}
        <b-spinner v-if="isLoading" small></b-spinner>
      </b-button>
      <b-button class="mt-1" variant="danger" block @click="hideModal"
        >Cancelar</b-button
      >
    </b-modal>
  </b-card>
</template>

<script>
import { mapState } from 'vuex'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BForm,
  BButton,
  BCard,
  BFormCheckbox,
  BSpinner
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { required } from '@validations'
import mixinsGlobal from '@/mixins'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import { verificarPermissao } from '@/auth/utils'
import ButtonsFormVue from '@/layouts/components/ButtonsForm.vue'
import _ from 'lodash'

export default {
  mixins: [mixinsGlobal],
  computed: {
    ...mapState('combosModule', {
      comboEspecialidades: (state) => state.comboEspecialidades,
      comboConselhos: (state) => state.comboConselhos,
      comboPerfilProfissional: (state) => state.comboPerfilProfissional
    }),
    ...mapState('professionsAtendimentoSettingsModule', {
      profissional: (state) => state.profissional
    })
  },
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BButton,
    BCard,
    ValidationProvider,
    ValidationObserver,
    BFormCheckbox,
    vSelect,
    flatPickr,
    ButtonsFormVue,
    BSpinner
  },
  directives: {
    Ripple
  },
  data() {
    return {
      verificarPermissao,
      titleForm: 'Cadastro de Profissional',
      perfilSelecionado: null,
      isLoading: false,
      selected: null,
      seachTerm: '',
      options: [],
      comboProfissionals: [],
      required,
      form: {
        registro: {
          id: null
        },
        dataInicio: '',
        dataTermino: '',
        numeroRegistroProfissional: '',
        conselho: {
          id: null
        },
        especialidades: [],
        ativo: true
      }
    }
  },
  async mounted() {
    this.cleanObjeto()
    this.form = JSON.parse(JSON.stringify(this.profissional))
    await this.$store.dispatch('combosModule/loadComboEspecialidades')
    await this.$store.dispatch('combosModule/loadComboConselhos')
    await this.$store.dispatch('combosModule/loadComboPerfilProfissional')
  },
  methods: {
    handleSaveProfile() {
      if (!this.perfilSelecionado) {
        this.MensagemError('Erro', 'Selecione um perfil')
        return
      }
      const data = {
        profissional: this.form.id,
        perfil: this.perfilSelecionado.id
      }
      this.isLoading = true
      this.$store
        .dispatch('atendimentoProfissionalModule/iserirUsuario', data)
        .then((response) => {
          if (response.error) {
            this.MensagemError('Erro', response.message)
            this.isLoading = false
            return
          }
          this.$refs['my-modal'].toggle('#toggle-btn')
          this.isLoading = false
        })
        .catch((error) => {
          this.MensagemError('Erro', error.message)
          return
        })
    },
    hideModal() {
      this.$refs['my-modal'].hide()
    },
    onSearch(search, loading) {
      loading(true)
      this.search(loading, search, this)
    },
    search: _.debounce(async (loading, search, vm) => {
      const registrosEncontrados = await vm.$store.dispatch(
        'professionsAtendimentoSettingsModule/searchProfessional',
        search
      )
      if (registrosEncontrados.response) {
        vm.MensagemError(
          'Falha na busca',
          `${registrosEncontrados.response.data.message}`
        )
        return
      }

      if (registrosEncontrados.data.content.length === 0) {
        vm.MensagemError('Zero registros', 'Nenhum registro encontrado')
        return
      }
      vm.comboProfissionals = registrosEncontrados.data.content
      loading(false)
    }, 350),
    async seachProfissional() {
      if (this.seachTerm && this.seachTerm.length > 3) {
        const registrosEncontrados = await this.$store.dispatch(
          'professionsAtendimentoSettingsModule/searchProfessional',
          this.seachTerm
        )
        if (registrosEncontrados.response) {
          this.MensagemError(
            'Falha na busca',
            `${registrosEncontrados.response.data.message}`
          )
          return
        }

        if (registrosEncontrados.data.content.length === 0) {
          this.MensagemError('Zero registros', 'Nenhum registro encontrado')
          return
        }
        this.comboProfissionals = registrosEncontrados.data.content
      }
    },
    cancel() {
      this.$emit('cancelar')
      this.cleanObjeto()
    },
    async save() {
      const isValid = await this.$refs.form.validate()

      if (isValid) {
        const action = this.form.id
          ? 'professionsAtendimentoSettingsModule/edit'
          : 'professionsAtendimentoSettingsModule/insertNew'

        const response = await this.$store.dispatch(action, this.form)

        if (response.error) {
          this.MensagemError('Falha ao Salvar', response.message)
          return
        }

        const message = this.form.id
          ? 'Editado com sucesso!'
          : 'Incluido com sucesso!'
        this.showMessageSuccess('Salvar', message)
        this.cancel()
      }
    },
    cleanObjeto() {
      this.form = {
        registro: {
          id: null
        },
        dataInicio: '',
        dataTermino: '',
        numeroRegistroProfissional: '',
        conselho: {
          id: null
        },
        especialidades: [],
        ativo: true
      }
    }
  }
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
